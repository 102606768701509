import { useMemo, useRef } from 'react';

import { BootstrapContext } from 'App';
import { useContext } from 'react';

import useSettings from './useSettings';

interface SettingHookReturn {
    loading: boolean;
    data?: any;
    error: any;
    refetch: () => void;
}

const getSetting = (key: string | string[], settingsData: any) => {
  if (typeof(key) === 'string')
    return settingsData?.[key];
  else {
    const result: any = {};
    key.forEach(k => result[k] = settingsData?.[k]);
    return result;
  }
};

export default function useSettingsEntry(settingKey: string | string[]): SettingHookReturn {
  const prevKStr = useRef<string>();
  const prevKey = useRef<string | string[]>();
  const key = useMemo(() => {
    const keyStr = typeof(settingKey) === 'string' ? settingKey : JSON.stringify(settingKey);
    if (prevKStr.current !== keyStr) {
      prevKStr.current = keyStr;
      prevKey.current = settingKey;
    }
    return prevKey.current!;
  }, [settingKey]);

  const isFirstRequest = useRef<boolean>(true);
  const { settings: settingsContext } = useContext(BootstrapContext);
  const { data: settings, refetch: _refetch, loading, error } = useSettings();

  const refetch = () => {
    isFirstRequest.current = false;
    _refetch();
  };

  const data = useMemo(() => {
    const settingCtx = isFirstRequest.current ? getSetting(key, settingsContext) : undefined;
    return settingCtx ?? getSetting(key, settings);
  }, [key, settings, settingsContext]);

  return {
    loading,
    data,
    error,
    refetch,
  };
}