import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'hooks/useApi';
import useConfiguration from 'hooks/useConfiguration';

import { Form } from 'react-bootstrap';
import useRights from 'hooks/useRights';

interface Props {
  removed?: boolean;
  editFieldWidthPercent?: number;
  defaultTitle: string;
  objectType?: string;
  id?: number;
  classOverride?: string;
  hasRightsToEdit?: boolean;
  onEditCallback?: (title: string) => void;
  fieldName?: string;
  header?: number;
  hideIdInDev?: boolean;
  getCustomComponent?: (removed: boolean, onClick: any, content: string) => JSX.Element;
}

export default function EditableTitle({ removed, editFieldWidthPercent, fieldName = 'title', header, hideIdInDev, defaultTitle, objectType, id, classOverride, hasRightsToEdit, onEditCallback, getCustomComponent }: Props): JSX.Element {
  const { hasRights } = useRights();
  const [editing, setEditing] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(defaultTitle);
  const [fieldValue, setFieldValue] = useState<string>(defaultTitle);
  const { t } = useTranslation();
  const api = useApi();
  const { isDev } = useConfiguration();

  useEffect(() => {
    setTitle(defaultTitle);
    setFieldValue(defaultTitle);
  }, [defaultTitle]);

  const onEdit = () => {
    if (id === undefined || !objectType) {
      setTitle(fieldValue);
      setEditing(false);
      if (onEditCallback) onEditCallback(fieldValue);
    } else {
      const data = {
        id,
      } as any;
      data[fieldName] = fieldValue;

      api.create(`/api/admin/crm/${objectType}/update`, data).then(resp => {
        if (resp.data.success) {
          setTitle(fieldValue);
          setEditing(false);
          if (onEditCallback) onEditCallback(fieldValue);
        } else {
          console.log(resp);
        }
      });
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setEditing(false);
      setFieldValue(title);
    } else if (e.key === 'Enter') {
      onEdit();
    }
  }

  if (!editing && getCustomComponent) {
    const content = `${(!hideIdInDev && isDev && id !== undefined) ? `ID: ${id} - ` : ''}${title || defaultTitle}`;
    return <>{getCustomComponent(removed || false, () => setEditing(true), content)}</>
  }

  const HeadingTag = header !== undefined ? `h${header}` : 'span';
  if (!editing) return (
    <div className={`${classOverride?.includes('w-') ? 'w-100' : ''} d-inline-block text-wrap text-break`}>
      {/* @ts-ignore known due to the dynamic nature of the h tag*/}
      <HeadingTag className={(classOverride) ? classOverride : `${header !== undefined ? 'header-title' : ''} m-0`} 
                  onClick={() => { if (hasRightsToEdit || hasRights(`crm:${objectType}:update`)) { setEditing(true); }}} 
                  style={(removed) ? { textDecoration: 'line-through' } : undefined}>
        {(!hideIdInDev && isDev && id !== undefined) ? `ID: ${id} - ` : ''}{title || defaultTitle}
      </HeadingTag>
    </div>
  );

  return (
    <Form.Control
        type="text"
        placeholder={t('crm.kanban.board.addNewTable.name')}
        name={fieldName}
        id={fieldName}
        className="form-control form-control-light d-inline-block"
        style={{maxWidth: `${editFieldWidthPercent ?? '50'}%`, minHeight: (classOverride) ? '75px' : undefined}}
        autoFocus={true}
        value={fieldValue}
        onBlur={onEdit}
        onKeyDown={onKeyDown}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.value)}
    />
  );
}