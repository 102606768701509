export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  API_RIGHTS_LOADED = '@@auth/RIGHTS_LOADED',
  API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

  LOGIN_USER = '@@auth/LOGIN_USER',
  LOGOUT_USER = '@@auth/LOGOUT_USER',

  RESET = '@@auth/RESET',
}
