import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { UserData as ReducerUserData } from './reducers';

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  whoami as whoamiApi,
  rights as rightsApi,
} from 'helpers/';

// actions
import { authApiResponseSuccess, authApiResponseError, rightsLoaded } from './actions';

// constants
import { AuthActionTypes } from './constants';

interface UserData {
  payload: ReducerUserData;
  type: string;
}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
  let errorText: any = undefined;
  try {
    const response = yield call(loginApi, { username, password });
    if (response?.data?.success) {
      const user = yield call(whoamiApi);
      window.apiCoreSingleton.setLoggedInUser(user.data);
      const rights = yield call(rightsApi);
      yield put(rightsLoaded(AuthActionTypes.API_RIGHTS_LOADED, rights.data));
      yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user.data));
    } else {
      errorText = (response?.data?.reasons) ? response?.data?.reasons[0] : response?.data?.reason;
      if (!errorText) errorText = true;
      window.apiCoreSingleton.setLoggedInUser(null);
    }
  } catch (error: any) {
    errorText = (error?.data?.reasons) ? error?.data?.reasons[0] : error?.data?.reason;
    if (!errorText) errorText = true;
    window.apiCoreSingleton.setLoggedInUser(null);
  }

  if (errorText)
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, errorText === true ? 'Érvénytelen belépési adatok.' : errorText));
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    window.apiCoreSingleton.setLoggedInUser(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

function* authSaga() {
  yield all([fork(watchLoginUser), fork(watchLogout)]);
}

export default authSaga;
