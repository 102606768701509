export function isCacheable(namespace: string, object: string): boolean {
  return object === 'tasklabel' || object === 'taskstatus' || object === 'workspace';
}

export function getCacheKey(namespace: string, object: string): string {
  let cacheKey: string = '';
  if (isCacheable(namespace, object)) {
    switch (object) {
      case 'tasklabel':
      case 'taskstatus':
      case 'workspace':
        cacheKey = `all_${object}`;
      break;
    }
  }

  return cacheKey;
}