// action constants
import { LayoutActionTypes, LayoutBackgroundData, LayoutStateTypes } from './constants';

// actions
import { ChangeSiteTitlePayload, LayoutActionType } from './actions';
import { MenuItemEntry } from 'types/MenuItemEntry';

const INIT_STATE: LayoutStateTypes = {
  siteMenuItems: undefined,
  siteTitle: undefined,
  defaultSiteTitle: undefined,
  siteEditableData: undefined,
  siteBackgroundData: undefined,
  path: undefined,
};

const Layout = (state: LayoutStateTypes = INIT_STATE, action: LayoutActionType<ChangeSiteTitlePayload | string | MenuItemEntry[] | any[] | LayoutBackgroundData | undefined>): LayoutStateTypes => {
  switch (action.type) {
    case LayoutActionTypes.SET_MENU_ITEMS:
      return {
        ...state,
        siteMenuItems: action.payload as MenuItemEntry[],
      };
    case LayoutActionTypes.CHANGE_SITE_TITLE:
      return {
        ...state,
        siteTitle: (action.payload as ChangeSiteTitlePayload).title,
        siteEditableData: (action.payload as ChangeSiteTitlePayload).editable,
        defaultSiteTitle: (action.payload as ChangeSiteTitlePayload).defaultTitle,
        path: (action.payload as ChangeSiteTitlePayload).path
      };
    case LayoutActionTypes.CHANGE_BREADCRUMB_ITEMS:
      return {
        ...state,
      };
    case LayoutActionTypes.CHANGE_BACKGROUND_DATA:
      return {
        ...state,
        siteBackgroundData: action.payload as LayoutBackgroundData
      };
    default:
      return state;
  }
};

export default Layout;
