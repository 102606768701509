import { all, call, fork, select, takeEvery } from 'redux-saga/effects';

// constants
import { LayoutActionTypes } from './constants';

function changeSiteTitle(title: string, defaultTitle: string, notificationCount: number): any {
  if (title) {
    let notificationData = '';
    if (notificationCount && Number.isFinite(notificationCount) && notificationCount > 0) {
      notificationData = `(${notificationCount}) `;
    }
    document.title = `${notificationData}${title} - ${!defaultTitle ? 'Avorasoft CRM' : defaultTitle}`;
  } else {
    document.title = `${!defaultTitle ? 'Avorasoft CRM' : defaultTitle}`;
  }
}

const getSiteTitle = (state: any): string => state.Layout.siteTitle as string;
const getDefaultSiteTitle = (state: any): string => state.Layout.defaultSiteTitle as string;
const getNotificationCount = (state: any): number => state.Notification.notificationCount as number;

function* onSiteTitleChange() {
  try {
    //@ts-ignore
    let siteTitle = yield select(getSiteTitle);
    //@ts-ignore
    let defaultSiteTitle = yield select(getDefaultSiteTitle);
    //@ts-ignore
    let notificationCount = yield select(getNotificationCount);
    yield call(changeSiteTitle, siteTitle, defaultSiteTitle, notificationCount);
  } catch (error) {}
}

export function* watchTitleChange(): any {
  yield takeEvery(LayoutActionTypes.CHANGE_SITE_TITLE, onSiteTitleChange);
}

function* LayoutSaga(): any {
  yield all([fork(watchTitleChange)]);
}

export default LayoutSaga;
