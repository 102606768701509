import { NotificationEntry } from 'types/NotificationEntry';

export enum NotificationActionTypes {
  SET_NOTIFICATIONS = '@@notification/SET_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS = '@@notification/CLEAR_NOTIFICATIONS',
  READ_NOTIFICATION = '@@notification/READ_NOTIFICATION'
}

export interface NotificationStateTypes {
  notificationCount: number
  notifications: NotificationEntry[]
}
