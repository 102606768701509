import React from 'react';

import Routes from './routes/Routes';

import Swal from 'sweetalert2';

// Themes

import './assets/scss/Avorasoft.scss';
// For Default import Default.scss
//import './assets/scss/Default.scss';
// For Dark import Default-Dark.scss
//import './assets/scss/Default-Dark.scss';

// For Saas import Saas.scss
// import './assets/scss/Saas.scss';
// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';
// For Modern dark demo import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Creative-Dark.scss
// import './assets/scss/Creative-Dark.scss';

// For Purple demo import Purple.scss
// import './assets/scss/Purple.scss';
// For Purple dark demo import Purple-Dark.scss
// import './assets/scss/Purple-Dark.scss';

// For Material demo import Material.scss
// import './assets/scss/Material.scss';
// For Material dark demo import Material-Dark.scss
// import './assets/scss/Material-Dark.scss';

interface BootstrapContextType {
  settings: any;
  setSettings: (settings: any) => void;
  miscForms: any;
  setMiscForms: (miscForms: any) => void;
}

interface SwalContextType {
  fire: typeof Swal.fire
}

// @ts-ignore
export const SwalContext = React.createContext<SwalContextType>({ fire: undefined });
export const BootstrapContext = React.createContext<BootstrapContextType>({ settings: {}, setSettings: () => { }, miscForms: {}, setMiscForms: () => { } });
export default function App(): JSX.Element {
  let observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      let oldValue = mutation.oldValue;
      let newValue = mutation.target.textContent;
      if (oldValue !== newValue) {
        var elements = document.getElementsByTagName('input');
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          if (!element.className.includes('login')) {
            if (element.className.includes('no-input-history') || element.parentElement?.className.includes('no-input-history'))
              element.autocomplete = 'off';
            else
              element.autocomplete = 'new-password';
          }
        }
      }
    });
  });
  
  observer.observe(document.body, {
    subtree: true, 
    childList: true
  });
  
  return <Routes />;
}
