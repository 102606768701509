import useSettingsEntry from 'features/settings/hooks/useSettingsEntry';
import { useMemo } from 'react';
import { MenuItemEntry } from 'types/MenuItemEntry';

export const useMenuItems = (): MenuItemEntry[] => {
    const { data: adminRightVal } = useSettingsEntry('system.default_rights_admin');
    const { data: headAdminRightVal } = useSettingsEntry('system.default_rights_headadmin');

    const { data: hasAbout } = useSettingsEntry('work.allow_about');

    const adminRight = useMemo(() => {
        return adminRightVal ? parseInt(adminRightVal) : 0;
    }, [adminRightVal]);

    const headAdminRight = useMemo(() => {
        return headAdminRightVal ? parseInt(headAdminRightVal) : 0;
    }, [headAdminRightVal]);

    return [
        {
            key: 'home',
            label: 'Főoldal',
            isTitle: false,
            icon: 'home',
            parentKey: 'root',
            url: '/',
        },
        /*    {
            key: 'crm',
            label: 'CRM',
            isTitle: false,
            icon: 'users',
            parentKey: 'root',
            url: '/crm',
            hiddenFromBreadcrumb: false,
            breadcrumbReadOnly: true,
            children: [*/
        {
            key: 'crm-todos',
            label: 'Teendők',
            url: '/crm/todos',
            icon: 'far fa-clipboard ',
            parentKey: 'crm',
            requiredRight: 'crm:todo:read',
        },
        {
            key: 'crm-tasks',
            label: 'Munkaterek',
            url: '/crm/boards',
            icon: 'fab fa-brands fa-trello',
            parentKey: 'crm',
            requiredRight: 'crm:workspace:read',
        },
        {
            key: 'crm-meetings',
            label: 'Meetingek',
            url: '/crm/meetings',
            icon: 'fas fa-chalkboard-teacher',
            parentKey: 'crm',
            requiredRight: 'crm:meeting:read',
        },
        {
            key: 'crm-calendar',
            label: 'Naptár',
            url: '/crm/calendar',
            icon: 'far fa-calendar-alt',
            parentKey: 'crm',
            requiredRight: 'crm:calendar:read',
        },
        {
            key: 'crm-phonebook',
            label: 'Kapcsolatok',
            url: '/crm/phonebook',
            icon: 'far fa-address-book',
            parentKey: 'crm',
            requiredRight: 'crm:phonebook:read',
        },
        {
            key: 'crm-chat',
            label: 'Chat',
            url: '/crm/chat',
            icon: 'far fa-comment-dots',
            parentKey: 'crm',
            requiredRight: 'crm:employeechat:read',
        },
        {
            key: 'crm-employees',
            label: 'Munkatársak',
            url: '/crm/employees',
            icon: 'users',
            parentKey: 'crm',
            requiredRight: 'crm:employee:read',
        },
        {
            key: 'crm-companies',
            label: 'Cégek',
            url: '/crm/companies',
            icon: 'far fa-building ',
            parentKey: 'crm',
            requiredRight: 'crm:company:read',
        },
        {
            key: 'crm-notes',
            label: 'Jegyzetek',
            url: '/crm/notes',
            icon: 'far fa-edit',
            parentKey: 'crm',
            requiredRight: 'crm:note:read',
        },
        {
            key: 'crm-calllogs',
            label: 'Híváslog',
            url: '/crm/calllogs',
            icon: 'fas fa-phone',
            parentKey: 'crm',
            requiredRight: 'crm:calllog:read',
        },
        {
            key: 'crm-support',
            label: 'Ügyfélszolgálat',
            url: '/crm/support',
            icon: 'mdi mdi-account-supervisor-circle-outline',
            parentKey: 'crm',
            requiredRight: 'crm:support:read',
        },
        {
            key: 'crm-sales',
            label: 'Értékesítés',
            url: '/crm/sales',
            icon: 'fas fa-money-bill-wave',
            parentKey: 'crm',
            requiredRight: 'crm:sales:read',
        },
        {
            key: 'crm-stats',
            label: 'Statisztikák',
            url: '/crm/stats',
            icon: 'pie-chart',
            parentKey: 'crm',
            //requiredRight: 'crm:company:read'
        },
        {
            key: 'crm-files',
            label: 'Dokumentumok',
            url: '/crm/files',
            icon: 'far fa-folder-open',
            parentKey: 'crm',
            requiredRight: 'crm:filemanagerfolder:read',
        },
        {
            key: 'about',
            label: 'Névjegy',
            isTitle: false,
            url: '/about',
            icon: 'fas fa-info-circle',
            hidden: hasAbout === true ? false : true,
        },
        //]
        //},
        {
            key: 'app-settings',
            label: 'Beállítások',
            isTitle: false,
            icon: 'settings',
            children: [
                {
                    key: 'app-settings-auditlog',
                    label: 'Felhasználó log',
                    url: '/settings/auditlog',
                    parentKey: 'app-settings',
                    requiredRight: 'system:notification:readother',
                },
                {
                    key: 'app-settings-worklog',
                    label: 'Munka log',
                    url: '/settings/worklog',
                    parentKey: 'app-settings',
                },
                {
                    key: 'app-settings-rights',
                    label: 'Jogosultságok',
                    url: '/settings/rights',
                    parentKey: 'app-settings',
                    requiredRight: 'system:rights:read',
                },
                {
                    key: 'app-settings-formbuilder',
                    label: 'Egyedi mezők',
                    url: '/settings/fields',
                    parentKey: 'app-settings',
                    requiredRight: 'crm:taskform:read',
                    minimumRight: adminRight,
                },
                {
                    key: 'app-settings-templates',
                    label: 'Sablonok',
                    url: '/settings/templates',
                    parentKey: 'app-settings',
                    minimumRight: adminRight,
                },
                {
                    key: 'app-settings-persistedsettings',
                    label: 'Admin beállítások',
                    url: '/settings/persistedsettings',
                    parentKey: 'app-settings',
                    minimumRight: adminRight,
                },
            ],
        },
        {
            key: 'sysadmin-settings',
            label: 'Főadmin Beállítások',
            isTitle: false,
            icon: 'settings',
            minimumRight: headAdminRight,
            children: [
                {
                    key: 'sysadmin-settings-cache',
                    label: 'Cache ürítés',
                    url: '/sysadmin/cache',
                    parentKey: 'sysadmin-settings',
                },
                {
                    key: 'sysadmin-settings-userlimit',
                    label: 'User limits',
                    url: '/sysadmin/userlimit',
                    parentKey: 'sysadmin-userlimit',
                },
                {
                    key: 'sysadmin-settings-smtp',
                    label: 'SMTP beállítások',
                    url: '/sysadmin/smtp',
                    parentKey: 'sysadmin-smtp',
                },
            ],
        },
        {
            key: 'logOut',
            label: 'Kijelentkezés',
            isTitle: false,
            url: '/auth/logout',
            icon: 'log-out',
        },
    ];
};
