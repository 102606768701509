// action constants
import { PersistedSettingsActionTypes, PersistedSettingsTypes } from './constants';

// actions
import { SimpleKV } from './actions';

const INIT_STATE: PersistedSettingsTypes = {
  hideArchivedData: false,
};

const PersistedSettings = (state: PersistedSettingsTypes = INIT_STATE, action: any): PersistedSettingsTypes => {
  switch (action.type) {
    case PersistedSettingsActionTypes.SET_SETTINGS_VALUE:
      const setPayload = action.payload as SimpleKV;
      const newState = Object.assign({}, state);
      newState[setPayload.key] = setPayload.value;
      return newState;
    default:
      return state;
  }
};

export default PersistedSettings;
