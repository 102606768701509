import React, { CSSProperties, Suspense, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Modal } from 'react-bootstrap';

// actions
import { ackError, changeSidebarType } from '../redux/actions';

// store
import { RootState, AppDispatch } from '../redux/store';

// constants
import { LayoutTypes, SideBarTypes } from '../constants';

// utils
import { areObjectsEqual, changeBodyAttribute } from '../utils';

// Preloaded components
import Spinner from 'components/Spinner';
import PageTitle from 'components/PageTitle';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <Spinner />;

interface VerticalLayoutProps {
    children?: any;
}

const VerticalLayout = ({ children }: VerticalLayoutProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        showError,
        errorText,
        backgroundData,
        leftSideBarType,
    } = useSelector((state: RootState) => ({
        showError: state.Error.showError,
        errorText: state.Error.errorText,
        backgroundData: state.Layout.siteBackgroundData,
        leftSideBarType: state.LegacyLayout.leftSideBarType,
    }), areObjectsEqual);

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    const [menuDiv, setMenuDiv] = useState<HTMLDivElement>();
    const [menuWidth, setMenuWidth] = useState<number>();

    /*
    layout defaults
    */
    useEffect(() => {
        changeBodyAttribute('data-layout-mode', LayoutTypes.LAYOUT_VERTICAL);
    }, []);

    useEffect(() => {
        changeBodyAttribute('data-sidebar-size', leftSideBarType);
    }, [leftSideBarType]);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    const closeMenu = () => {
        if (isMenuOpened) {
            setIsMenuOpened(false);
    
            if (document.body)
                document.body.classList.remove('sidebar-enable');
        }
    };

    const updateDimensions = useCallback(() => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth <= 1028) {
            if (leftSideBarType !== SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED)
                dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED));
        } else if (window.innerWidth > 1028) {
            if (leftSideBarType !== SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT)
                dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT));
        }
    }, [dispatch, leftSideBarType]);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [dispatch, updateDimensions]);

    const closeErrorModal = () => dispatch(ackError(true));

    let wrapperStyle: CSSProperties = {};
    if (backgroundData && backgroundData.backgroundColor) {
        wrapperStyle.backgroundColor = backgroundData.backgroundColor;
    } else if (backgroundData && backgroundData.backgroundImage) {
        wrapperStyle.backgroundImage = `url('${backgroundData.backgroundImage}')`;
        wrapperStyle.backgroundSize = 'cover';
    }

    const isCondensed: boolean = leftSideBarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;

    const updateMenuDivWidth = () => {
        if (menuDiv)
            setMenuWidth(menuDiv.clientWidth)
    };

    useEffect(() => {
        if (menuDiv)
            setTimeout(updateMenuDivWidth, 100);
    }, [isMenuOpened, isCondensed]);

    return (
        <>
            <div id="wrapper" style={wrapperStyle} onLoad={updateDimensions}>
                <Suspense fallback={loading()}>
                    <Topbar openLeftMenuCallBack={openMenu} hideLogo={isCondensed} />
                </Suspense>
                <Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} refCallback={e => setMenuDiv(e)} />
                </Suspense>
                <div id="content-page" style={{ marginLeft: (!isMenuOpened && isCondensed) ? `${menuWidth}px` : ((isMenuOpened && isCondensed) ? `${menuWidth}px` : '') }} onClick={closeMenu}>
                    <div className="content">
                        <Container fluid style={{ position: 'relative', height: '100%' }}>
                            <PageTitle />
                            <Suspense fallback={loading()}>{children}</Suspense>

                            <Modal show={showError} onHide={closeErrorModal} size="sm">
                                <div className="modal-filled bg-danger">
                                    <Modal.Body className="p-4">
                                        <div className="text-center">
                                            <i className="dripicons-cross h1 text-white"></i>
                                            <p className="mt-3 text-white">
                                                {errorText}
                                            </p>
                                            <button type="button" onClick={closeErrorModal} className="btn btn-light my-2">
                                                Rendben
                                            </button>
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                        </Container>
                    </div>
                </div>
                <Suspense fallback={loading()}>
                    <Footer />
                </Suspense>
            </div>
        </>
    );
};
export default VerticalLayout;
