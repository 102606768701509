import { NetteRightGroupSchema } from 'types/schema';

export default function getAllRights(): Promise<any> {
  const apiCore = window.apiCoreSingleton;
  return new Promise<NetteRightGroupSchema[]>((resolve, reject) => {
    if (!apiCore.isUserAuthenticated()) {
      reject(new Error('user not logged in'));
      return;
    }

    apiCore.get('/api/admin/system/settings/read').then(resp => {
      resolve(resp.data);
    }).catch(err => reject(err));
  });
}