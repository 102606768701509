import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState, AppDispatch } from 'redux/store';
import { authApiResponseSuccess, resetAuth, setMenuItems, setNotifications, setChats, setServerTimestamp, rightsLoaded } from 'redux/actions';
import { AuthActionTypes } from 'redux/auth/constants';

import Swal from 'sweetalert2';
import { withSwal } from 'react-sweetalert2';

import useApi from 'hooks/useApi';

import App, { BootstrapContext, SwalContext } from 'App';
import { useMenuItems } from 'constants/menu';
import { AxiosResponse } from 'axios';
import { MenuItemEntry } from 'types/MenuItemEntry';
import { findMenuItem } from 'helpers/menu';
import { TaskformfiledSchemaArray } from 'features/taskformfields/hooks/useTaskformfields';

interface Props {
  swal: {
    fire: typeof Swal.fire
  }
}

function ApiBootstrap({ swal }: Props): JSX.Element {
  const api = useApi();
  const dispatch = useDispatch<AppDispatch>();
  const MENU_ITEMS = useMenuItems();
  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<{ settings: any, formIds: number[] }>();
  const [miscForms, setMiscForms] = useState<any>({});
  const user = useSelector((state: RootState) => state.Auth.user);

  useEffect(() => {
    if (loading) {
      Promise.all([api.get('/api/admin/system/settings/read')]).then((values) => {
        const settingsData = values[0].data;
        
        const defaultCustomforms: any = {};
        const forms = TaskformfiledSchemaArray.flatMap(schema => {
          const ids = settingsData[`work.default_${schema}_form`]?.map((id: string) => parseInt(id)) ?? [];
          defaultCustomforms[schema] = ids;
          return ids;
        });
        window.defaultCustomforms = defaultCustomforms;

        const aliasSettings = settingsData['system.alias'];
        if (typeof(aliasSettings) === 'object' && !Array.isArray(aliasSettings))
          window.alias = aliasSettings;

        const metaSettings = settingsData['system.meta'];
        if (typeof(metaSettings) === 'object' && !Array.isArray(metaSettings))
          window.meta = metaSettings;

        setSettings({ settings: settingsData, formIds: forms });
      });
    }
  }, []);

  useEffect(() => {
    if (dispatch && settings?.settings) {
      const menus = settings.settings['system.menus'] as { key: string, label?: string, hidden?: boolean, children?: { key: string, label?: string }[] }[] | undefined;
      let customMenu: MenuItemEntry[] = [];

      if (menus && Array.isArray(menus))
        customMenu = menus.filter(m => findMenuItem(MENU_ITEMS, m.key)).map(m => {
          const menuItem = findMenuItem(MENU_ITEMS, m.key)!;
          return {
            ...menuItem,
            label: m.label ? m.label : menuItem.label,
            hidden: m.hidden,
            children: m.children?.filter(c => findMenuItem(MENU_ITEMS, c.key)).map(c => {
              const childMenuItem = findMenuItem(MENU_ITEMS, c.key)!;
              return {
                ...childMenuItem,
                label: c.label ? c.label : childMenuItem.label,
                children: undefined
              }
            })
          };
        });

      if (customMenu.length > 0) {
        const defaultMenuKeys = MENU_ITEMS.flatMap(m => { return [m.key, ...(m.children?.map(c => c.key) ?? [])]; });
        const customMenuKeys = customMenu.flatMap(m => { return [m.key, ...(m.children?.map(c => c.key) ?? [])]; });
        defaultMenuKeys.forEach(k => {
          if (!customMenuKeys.includes(k)) {
            const item = findMenuItem(MENU_ITEMS, k);
            if (item)
              customMenu.unshift({...item, children: undefined});
          }
        });
      }

      dispatch(setMenuItems((customMenu.length > 0) ? customMenu : MENU_ITEMS));
    }
  }, [dispatch, MENU_ITEMS, settings?.settings]);

  useEffect(() => {
    if (api && dispatch && settings?.settings && user?.adminId) {
      const performHeartbeat = (timestamp?: number) => {
        api.get('/api/admin/account/heartbeat').then(resp => {
          if (resp.data.notifications) {
            dispatch(setNotifications(resp.data.notifications));
          }
          if (resp.data.chats) {
            const chats = resp.data.chats.filter((chat: any) => chat.members.includes(user.adminId) && chat.lastUserID !== user.adminId);
            dispatch(setChats(chats));
          }
        });

        if (timestamp !== undefined)
          dispatch(setServerTimestamp(timestamp * 1000));
        else
          api.get('/api/admin/system/settings/read').then(resp => {
            if (resp.status === 200)
              dispatch(setServerTimestamp(resp.data.timestamp * 1000));
          });
      }

      performHeartbeat(settings.settings['timestamp']);
      const interval = setInterval(performHeartbeat, 60000);
      return () => clearInterval(interval);
    }
  }, [api, dispatch, settings?.settings, user?.adminId]);

  useEffect(() => {
    if (window.location.pathname === '/auth/login') {
      dispatch(resetAuth());
      setLoading(false);
      return;
    }

    if (!settings) return;
    api.get('/api/admin/account/whoami').then(resp => {
      if (resp.data.success && resp.data.loggedIn) {
        dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, resp.data));

        api.get('/api/admin/system/rights/read').then(resp => {
          dispatch(rightsLoaded(AuthActionTypes.API_RIGHTS_LOADED, resp.data));
        });

        let miscForms: any = {};
        if (settings.formIds && Array.isArray(settings.formIds)) {
          const formPromises: Promise<AxiosResponse<any>>[] = [];
          settings.formIds.forEach((form) => {
            formPromises.push(api.get(`/api/admin/crm/taskform/read/id/${form}`));
          });
          Promise.all(formPromises).then((values) => {
            values.forEach((form) => {
              const id = form.data.id;
              miscForms[id] = form.data;
            });
            setMiscForms(miscForms);
            setLoading(false);
          }).catch(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      } else {
        dispatch(resetAuth());
        window.apiCoreSingleton.setLoggedInUser(null);
        window.location.href = '/auth/login';
      }
    }).catch(() => {
      dispatch(resetAuth());
      window.apiCoreSingleton.setLoggedInUser(null);
      window.location.href = '/auth/login';
    });
  }, [api, dispatch, settings]);

  if (loading)
    return <></>;

  return (
    <SwalContext.Provider value={{ fire: swal.fire }}>
      <BootstrapContext.Provider value={{ settings: settings?.settings ?? {}, setSettings, miscForms, setMiscForms }}>
        <App />
      </BootstrapContext.Provider>
    </SwalContext.Provider>
  );
}

export default withSwal(ApiBootstrap);