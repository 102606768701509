// action constants
import { SchemaStateTypes, SchemaActionTypes } from './constants';

// actions
import { SetSchemaCacheDataPayload, SchemaActionType } from './actions';

const INIT_STATE: SchemaStateTypes = {
  cacheTtls: {},
  cacheData: {},
  cacheLastUpdated: {},
};

const SchemaCache = (state: SchemaStateTypes = INIT_STATE, action: SchemaActionType<SetSchemaCacheDataPayload | string | unknown | undefined>): SchemaStateTypes => {
  const newTtls: any = Object.assign({}, state.cacheTtls);
  const newData: any = Object.assign({}, state.cacheData);
  const newLastUpdated: any = Object.assign({}, state.cacheLastUpdated);
  switch (action.type) {
    case SchemaActionTypes.SET_SCHEMA_CACHE_DATA:
      const setPayload = action.payload as SetSchemaCacheDataPayload;
      if (setPayload.ttl)
        newTtls[setPayload.key] = setPayload.ttl;
      newData[setPayload.key] = setPayload.data;
      newLastUpdated[setPayload.key] = new Date().getTime();
      return {
        cacheData: newData,
        cacheTtls: newTtls,
        cacheLastUpdated: newLastUpdated,
      };
    case SchemaActionTypes.UNSET_SCHEMA_CACHE_DATA:
      const unsetPayload = action.payload as string;
      if (newTtls[unsetPayload]) delete newTtls[unsetPayload];
      if (newData[unsetPayload]) delete newData[unsetPayload];
      newLastUpdated[unsetPayload] = new Date().getTime();
      return {
        cacheData: newData,
        cacheTtls: newTtls,
        cacheLastUpdated: newLastUpdated,
      };
    case SchemaActionTypes.CLEAR_SCHEMA_CACHE_DATA:
      return {
        cacheData: {},
        cacheTtls: {},
        cacheLastUpdated: {},
      };
    default:
      return state;
  }
};

export default SchemaCache;
