import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface UseRightsReturn {
  hasRights: (rightId: string|string[]|undefined) => boolean
}

const useRights = (): UseRightsReturn => {
  const rights = useSelector((state: RootState) => state.Auth.rights);

  const hasRights = (rightId: string|string[]|undefined): boolean => {
    if (!rightId) return true;
    let toBeReturned = false;
    if (Array.isArray(rightId)) {
      rightId.forEach((right) => {
        if (rights[right] && rights[right] === true) {
          toBeReturned = true;
        }
      });
    } else {
      if (rights[rightId] && rights[rightId] === true) {
        toBeReturned = true;
      }
    }

    return toBeReturned;
  }

  return {
    hasRights,
  }
};

export default useRights;
