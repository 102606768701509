import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import legacyLayoutSaga from './legacyLayout/saga';
import layoutSaga from './layout/saga';
import notificationSaga from './notification/saga';
import chatSaga from './chat/saga';
import schemaCacheSaga from './schemaCache/saga';

export default function* rootSaga() {
  yield all([authSaga(), legacyLayoutSaga(), layoutSaga(), notificationSaga(), chatSaga(), schemaCacheSaga()]);
}
