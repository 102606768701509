// constants
import { AuthActionTypes } from './constants';

const INIT_STATE = {
  user: undefined,
  rights: {},
  rightsRaw: {},
  loading: false,
};

export interface UserData {
  username: string;
  password: string;
  loggedIn: boolean;
  adminId: number;
  adminDisplayName: string;
  adminImageName: string;
  adminRightName: string;
  adminFirstName: string;
  adminFullName: string;
}

interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.API_RIGHTS_LOADED
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET;
  payload: {
    actionType?: string;
    data?: UserData | {};
    error?: string;
  };
}

interface State {
  user?: UserData | {};
  rights: Record<string, boolean>;
  rightsRaw?: any;
  loading?: boolean;
  value?: boolean;
}

const Auth = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
          };
        }
        default:
          return state;
      }
    case AuthActionTypes.API_RIGHTS_LOADED:
      return {
        ...state,
        rights: convertRights(action.payload.data),
        rightsRaw: action.payload.data,
      }
    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        default:
          return state;
      }

    case AuthActionTypes.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
      };
    default:
      return state;
  }
};

function convertRights(nette: any): Record<string, boolean> {
  const endpoints = nette.endpoints;
  const entries: Record<string, boolean> = {};
  Object.keys(endpoints).forEach((endpointKey) => {
      const endpoint = endpoints[endpointKey];
      const endpointEntryKey = `${endpointKey}`;
      Object.keys(endpoint).forEach((mainKey) => {
          const main = endpoint[mainKey];
          const mainEntryKey = `${endpointEntryKey}:${mainKey}`;
          Object.keys(main).forEach((actionKey) => {
              const checked = main[actionKey];
              const key = `${mainEntryKey}:${actionKey}`;
              entries[key] = checked;
          });
      });
  });

  return entries;
}

export default Auth;
