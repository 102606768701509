// action constants
import { ServerActionTypes, ServerStateTypes } from './constants';

// actions
import { ServerActionType } from './actions';

const INIT_STATE = {
  timestamp: 0
};

const Server = (state: ServerStateTypes = INIT_STATE, action: ServerActionType<any>) => {
  switch (action.type) {
    case ServerActionTypes.SET_TIMESTAMP:
      return {
        ...state,
        timestamp: action.payload,
      };
    default:
      return state;
  }
};

export default Server;
