import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en/translation.json';
import translationHu from './locales/hu/translation.json';

//translations
const resources = {
    hu: {
        translation: translationHu,
    },
    en: {
        translation: translationEn,
    },
};

i18n//.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'hu',
        fallbackLng: 'hu',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
