import { useQuery } from 'react-query';

import getAllSettings from '../queries/getAllSettings';

interface SettingsHookReturn {
    loading: boolean;
    data: any;
    getKey: (key: string, fallback?: any) => any;
    error: any;
    refetch: () => void;
}

export default function useSettings(): SettingsHookReturn {
  const settingsQuery = useQuery(`features_settings_getAllSettings`, getAllSettings);
  const { data, error, refetch } = settingsQuery;

  const getKey = (key: string, fallback?: any): any => {
    if (settingsQuery.isLoading) return fallback;
    try {
      if (data[key]) {
        return data[key];
      } else {
        return fallback;
      }
    } catch(e) {
      return fallback;
    }
  }

  return {
    loading: settingsQuery.isLoading,
    data: data ?? [],
    getKey,
    error,
    refetch,
  }
}