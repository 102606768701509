export * from './api/';

type ParentInfo = {
    id: number;
    title: string;
}

export function getParentByKeyPart(parents: any, keyPart: string, resultIndex: number = 0): ParentInfo | undefined {
    const key = Object.keys(parents).filter(k => k.toLowerCase().includes(keyPart.toLowerCase()))[resultIndex];
    return key ? parents[key] : undefined;
}

export function getParentsByKeyPart(parents: any, keyPart: string): ParentInfo[] {
    const keys = Object.keys(parents).filter(k => k.toLowerCase().includes(keyPart.toLowerCase()));
    return keys.map(key => parents[key]);
}

export function hasParentWithKeyPartAndId(parents: any, keyPart: string, id: number): boolean {
    return Object.keys(parents).find(k => k.toLowerCase().includes(keyPart.toLowerCase()) && parents[k].id === id) !== undefined;
}

export function formatNumber(number: number | string): string {
    if (typeof(number) === 'string') {
        const strToNumber = parseInt(number);
        if (isNaN(strToNumber))
            return number;
        else
            number = strToNumber;
    }
    return number.toLocaleString('hu-HU');
}

export function scrollToElement(element: HTMLElement | null | string, onlyIfNotInViewport: boolean = false): void {
    if (typeof(element) === 'string')
        element = document.getElementById(element);
    
    if (element && (!onlyIfNotInViewport || !isInViewport(element))) {
        element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        //window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
    }
}

export function isInViewport(element: HTMLElement | null | string): boolean {
    if (typeof(element) === 'string')
        element = document.getElementById(element);
    
    if (element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
    return false;
}

type SortableItem = {
    parents: any;
    position: number;
};

export function sortByParentAndPosition<T extends SortableItem>(array: T[], parentKey: string): T[] {
    return array.sort((a, b) => {
        const parentA = getParentByKeyPart(a.parents, parentKey) ? 1 : 0;
        const parentB = getParentByKeyPart(b.parents, parentKey) ? 1 : 0;
        return (parentA !== parentB ? parentA - parentB : a.position - b.position);
    });
}