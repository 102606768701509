// constants
import { ErrorActionTypes } from './constants';

export interface ErrorActionType<TPayload> {
  type:
    | ErrorActionTypes.DISPLAY_ERROR
    | ErrorActionTypes.ACK_ERROR;
  payload?: TPayload;
}

export const displayError = (error: string): ErrorActionType<string> => ({
  type: ErrorActionTypes.DISPLAY_ERROR,
  payload: error,
});

export const displayNetworkError = (): ErrorActionType<string> => ({
  type: ErrorActionTypes.DISPLAY_ERROR,
  payload: 'Hiba történt az adatok lekérdezése során! Ellenőrizze az internet kapcsolatot, majd próbálja újra!',
});

export const ackError = (ack: boolean): ErrorActionType<boolean> => ({
  type: ErrorActionTypes.ACK_ERROR,
  payload: ack,
});