import { useContext, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { displayNetworkError } from 'redux/actions';
import { RootState } from 'redux/store';

import { AxiosResponse } from 'axios';

import { APICoreContext } from 'index';

import { APICore } from 'helpers/api/apiCore';

const useApi = (): APICore => {
    const dispatch = useDispatch();
    const apiCore = useContext(APICoreContext);
    const hideArchived = useSelector((state: RootState) => state.PersistedSettings.hideArchivedData);

    const ret: APICore = useMemo((): APICore => {
        return {
            get: (url, params, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.get(url, params).then(response => {
                        if (hideArchived && response.data && Array.isArray(response.data) && response.data.length > 0 && response.data[0].archived !== undefined) {
                            let newResponse = Object.assign({}, response);
                            newResponse.data = response.data.filter((data) => data.archived === false);
                            resolve(newResponse);
                        } else {
                            resolve(response);
                        }
                    }).catch(() => {
                        if (url.includes('api/admin/account/heartbeat')) return;
                        if (!props?.hideDefaultErrorMsg) dispatch(displayNetworkError());
                    });
                });
            },
            getFile: (url, params, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.getFile(url, params).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            getMultiple: (urls, params, props) => {
                return new Promise<AxiosResponse<any>[]>((resolve) => {
                    apiCore.getMultiple(urls, params).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            create: (url, data, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.create(url, data).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            createForm: (url, data, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.createForm(url, data).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            updatePatch: (url, data, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.updatePatch(url, data).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            update: (url, data, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.update(url, data).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            delete: (url, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.delete(url).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            createWithFile: (url, data, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.createWithFile(url, data).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            updateWithFile: (url, data, props) => {
                return new Promise<AxiosResponse<any>>((resolve) => {
                    apiCore.updateWithFile(url, data).then(resolve).catch(() => !props?.hideDefaultErrorMsg && dispatch(displayNetworkError()));
                });
            },
            isUserAuthenticated: apiCore.isUserAuthenticated,
            setLoggedInUser: apiCore.setLoggedInUser,
            getLoggedInUser: apiCore.getLoggedInUser,
            setUserInSession: apiCore.setUserInSession
        };
    }, [apiCore, dispatch]);

    return ret;
};

export default useApi;
