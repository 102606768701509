// constants
import { ChatEntry } from 'types/ChatEntry';
import { ChatActionTypes } from './constants';

export interface ChatActionType<TPayload> {
  type: ChatActionTypes.SET_CHATS | ChatActionTypes.READ_CHATS | ChatActionTypes.CLEAR_CHATS;
  payload?: TPayload;
}

export interface ReadChatPayload {
  chatId: number;
}

export const setChats = (chats: ChatEntry[]): ChatActionType<ChatEntry[]> => ({
  type: ChatActionTypes.SET_CHATS,
  payload: chats,
});

export const readChat = (chatData: ReadChatPayload): ChatActionType<ReadChatPayload> => ({
  type: ChatActionTypes.READ_CHATS,
  payload: chatData,
});

export const clearChats = (): ChatActionType<any> => ({
  type: ChatActionTypes.CLEAR_CHATS,
  payload: {}
});