import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
let store: any;

function loadKey(key: string): any {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch {
    return undefined;
  }
}

function initialState(): any {
  return {
    PersistedSettings: loadKey('avora::persistedSettings')
  }
}

export function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const localstore = createStore(reducers, initialState(), composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(rootSaga);
  store = localstore;
  store.subscribe(() => {
    saveState(store.getState().PersistedSettings);
  });
  return localstore;
}

function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('avora::persistedSettings', serializedState);
  } catch {
    // ignore write errors
  }
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
