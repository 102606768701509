import { all, fork, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
  markNotificationRead,
  markAllNotificationsRead,
} from 'helpers/';

// constants
import { NotificationActionTypes } from './constants';

interface ReadNotificationData {
  payload: {
    notificationId: number
  };
  type: string;
}

function* readNotification({ payload: { notificationId }, type }: ReadNotificationData): SagaIterator {
  try {
    yield call(markNotificationRead, { notificationId });
  } catch (error: any) {
    
  }
}

function* clearNotifications(): SagaIterator {
  try {
    yield call(markAllNotificationsRead);
  } catch (error: any) {

  }
}

export function* watchNotificationRead() {
  yield takeEvery(NotificationActionTypes.READ_NOTIFICATION, readNotification);
}

export function* watchNotificationsClear() {
  yield takeEvery(NotificationActionTypes.CLEAR_NOTIFICATIONS, clearNotifications);
}

function* notificationSaga() {
  yield all([fork(watchNotificationRead), fork(watchNotificationsClear)]);
}

export default notificationSaga;
