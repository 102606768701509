// constants
import { ServerActionTypes } from './constants';

export interface ServerActionType<TPayload> {
  type: ServerActionTypes.SET_TIMESTAMP;
  payload?: TPayload;
}

export const setServerTimestamp = (timestamp: number): ServerActionType<number> => ({
  type: ServerActionTypes.SET_TIMESTAMP,
  payload: timestamp,
});