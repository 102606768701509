// action constants
import { ChatActionTypes, ChatStateTypes } from './constants';

import { ChatEntry } from 'types/ChatEntry';

// actions
import { ChatActionType } from './actions';

const INIT_STATE = {
  chatCount: 0,
  chats: [],
};

const Chat = (state: ChatStateTypes = INIT_STATE, action: ChatActionType<any>) => {
  switch (action.type) {
    case ChatActionTypes.SET_CHATS:
      const unreadChatCount = (action.payload || []).filter((chat: ChatEntry) => chat.unread > 0).length;
      return {
        ...state,
        chatCount: unreadChatCount,
        chats: (action.payload) ? (action.payload as ChatEntry[]).sort((a,b)=>b.lastMessageTime-a.lastMessageTime) : [],
      };
    case ChatActionTypes.CLEAR_CHATS:
      return {
        ...state,
        chatCount: 0,
        chats: state.chats.map((chat) => {
          return {
            ...chat,
            unread: 0,
          };
        })
      };
    case ChatActionTypes.READ_CHATS:
      const newChats = (state.chats || []).map((chat) => {
        if (chat.id !== action.payload.chatId) return chat;
        return {
          ...chat,
          unread: 0,
        };
      });
      const newChatCount = newChats.filter((chat: ChatEntry) => chat.unread > 0).length;

      return {
        ...state,
        chatCount: newChatCount,
        chats: newChats,
      };
    default:
      return state;
  }
};

export default Chat;
