import axios from 'axios';

// content type
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        if( typeof response.data === 'string' && response.status !== 204 ) {
            try {
                const newData = JSON.parse(response.data);
                const newResp = Object.assign({}, response);
                newResp.data = newData;
                return newResp;
            } catch(e) {
                return Promise.reject(e);
            }
        }
        return response;
    },
    (error) => {
        if (error && error.response && error.response.status === 401) {
            window.apiCoreSingleton.setLoggedInUser(null);
            if (!window.location.href.endsWith('/auth/login'))
                window.location.href = '/auth/login';
            return Promise.reject('Unauthorized');
        }
        return Promise.reject(error.response);
    }
);

const AUTH_SESSION_KEY = 'avora_user';

const getUserFromCookie = (): any => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

interface ActionProps {
    hideDefaultErrorMsg?: boolean;
}

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url: string, params?: any, props?: ActionProps) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getFile = (url: string, params?: any, props?: ActionProps) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls: string, params?: any, props?: ActionProps) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: any, props?: ActionProps) => {
        return axios.post(url, data);
    };

    createForm = (url: string, data: URLSearchParams, props?: ActionProps) => {
        return axios.post(url, data);
    }

    /**
     * Updates patch data
     */
    updatePatch = (url: string, data: any, props?: ActionProps) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: any, props?: ActionProps) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url: string, props?: ActionProps) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url: string, data: FormData, props?: ActionProps) => {
        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, data, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any, props?: ActionProps) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();

        if (!user) {
            return false;
        }
        return true;
    };

    setLoggedInUser = (session: any) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };
    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromCookie();
    };

    setUserInSession = (modifiedUser: any) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

export { APICore };