import { NetteTaskformfieldReadSchema } from 'types/schema/NetteTaskformfieldSchema';

export default function getAllTaskformfields(formIds?: number[]): Promise<NetteTaskformfieldReadSchema[]> {
  const apiCore = window.apiCoreSingleton;
  return new Promise<NetteTaskformfieldReadSchema[]>((resolve, reject) => {
    if (!apiCore.isUserAuthenticated()) {
      reject(new Error('user not logged in'))
      return;
    }

    apiCore.get('/api/admin/crm/taskformfield/read').then(resp => {
      resolve(resp.status === 200 ? formIds ? resp.data.filter((field: any) => formIds.includes(field.parent)) : resp.data : []);
    }).catch(err => reject(err));
  });
}