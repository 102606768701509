// constants
import { NotificationEntry } from 'types/NotificationEntry';
import { NotificationActionTypes } from './constants';

export interface NotificationActionType<TPayload> {
  type: NotificationActionTypes.SET_NOTIFICATIONS | NotificationActionTypes.READ_NOTIFICATION | NotificationActionTypes.CLEAR_NOTIFICATIONS;
  payload?: TPayload;
}

export interface ReadNotificationPayload {
  notificationId: number;
}

export const setNotifications = (notifications: NotificationEntry[]): NotificationActionType<NotificationEntry[]> => ({
  type: NotificationActionTypes.SET_NOTIFICATIONS,
  payload: notifications,
});

export const readNotification = (notificationData: ReadNotificationPayload): NotificationActionType<ReadNotificationPayload> => ({
  type: NotificationActionTypes.READ_NOTIFICATION,
  payload: notificationData,
});

export const clearNotifications = (): NotificationActionType<any> => ({
  type: NotificationActionTypes.CLEAR_NOTIFICATIONS,
  payload: {}
});