import { useMemo } from 'react';
import { TaskformfiledSchemaArray, TaskformfiledSchemas } from 'features/taskformfields/hooks/useTaskformfields';
import useSettingsEntry from 'features/settings/hooks/useSettingsEntry';

export type DefaultCustomforms = {
  [schema in TaskformfiledSchemas]: number[];
}

const useAllDefaultCustomforms = (): [defaultForms: DefaultCustomforms, refetch: () => void] => {
  const { data: settings, refetch } = useSettingsEntry(TaskformfiledSchemaArray.map(schema => `work.default_${schema}_form`)) as { data?: { [x: string]: string[] }, refetch: () => void };

  const defaultForms = useMemo(() => {
    const obj: any = {};
    TaskformfiledSchemaArray.map(schema => obj[schema] = (settings?.[`work.default_${schema}_form`].map(id => parseInt(id)) ?? []))
    return obj;
  }, [settings]);

  return [defaultForms, refetch];
};

export default useAllDefaultCustomforms;
