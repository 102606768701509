import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// strore
import { RootState } from '../redux/store';

// All layouts containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';

import Error404Internal from 'pages/error/Error404Internal';
import Error404External from 'pages/error/Error404External';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import RouteChangeListener from './RouteChangeListener';

interface RoutesProps {}

const Routes = (props: RoutesProps) => {
  const layout = useSelector((state: RootState) => state.Layout);

  return (
    <BrowserRouter>
      <RouteChangeListener />
      <Switch>
        <Route path={publicProtectedFlattenRoutes.map((r: any) => r['path'])}>
          <DefaultLayout {...props} layout={layout}>
            <Switch>
              {publicProtectedFlattenRoutes.map((route: any, index: number) => {
                return (
                  !route.children && (
                    <route.route
                      key={index}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                );
              })}
              <Route path="*">
                <Error404External />
              </Route>
            </Switch>
          </DefaultLayout>
        </Route>

        <Route path={authProtectedFlattenRoutes.map((r: any) => r['path'])}>
          <VerticalLayout {...props}>
            <Switch>
              {authProtectedFlattenRoutes.map((route: any, index: number) => {
                return (
                  !route.children && (
                    <route.route
                      key={index}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                );
              })}
              <Route path="*">
                <Error404Internal />
              </Route>
            </Switch>
          </VerticalLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
