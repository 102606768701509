import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

const Error404Svg = () => {
  return (
      <svg viewBox="0 0 600 200">
          <symbol id="s-text">
              <text textAnchor="middle" x="50%" y="50%" dy=".35em">
                  404!
              </text>
          </symbol>
          <use className="text" href="#s-text"></use>
          <use className="text" href="#s-text"></use>
          <use className="text" href="#s-text"></use>
          <use className="text" href="#s-text"></use>
          <use className="text" href="#s-text"></use>
      </svg>
  );
};

export default function Error404Internal(): JSX.Element {
  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={6} xl={4} className="mb-4">
          <div className="error-text-box">
            <Error404Svg />
          </div>

          <div className="text-center">
            <h3 className="mt-0 mb-2">Az oldal nem található </h3>
            <p className="text-muted mb-3">
              Úgy látszik egy olyan oldalra tévedtél, ami nem létezik.
              Szó sincsen arról, hogy te rontottál volna el valamit, de az
              univerzum biztonsága érdekében talán jobb, ha visszalátogatsz a főoldalra.
            </p>
            <Link to="/" className="btn btn-success waves-effect waves-light">
              Főoldal
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}