import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Error from './error/reducers';
import Layout from './layout/reducers';
import LegacyLayout from './legacyLayout/reducers';
import Notification from './notification/reducers';
import Chat from './chat/reducers';
import PersistedSettings from './persistedSettings/reducers';
import SchemaCache from './schemaCache/reducers';
import Server from './server/reducers';

export default combineReducers({
  Auth,
  Error,
  Layout,
  LegacyLayout,
  Notification,
  Chat,
  PersistedSettings,
  SchemaCache,
  Server
});
