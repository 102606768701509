/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutPath } from 'redux/layout/constants';

import { Row, Col, Breadcrumb } from 'react-bootstrap';

import { MenuItemEntry } from "types/MenuItemEntry";

import EditableTitle from 'pages/crm/Kanban/Board/EditableTitle';
import { changeSiteTitle } from 'redux/actions';
import useSettingsEntry from 'features/settings/hooks/useSettingsEntry';
import { areObjectsEqual } from 'utils';

export default function PageTitle(): JSX.Element {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: defaultSiteTitle } = useSettingsEntry('work.site_title');
  const { classOverride, title, path, notificationCount, objectEditable, objectName, objectId, objectRemoved, editableData, menu } = useSelector((state: any) => {
    return {
      classOverride: state.Layout.siteEditableData?.className,
      title: state.Layout.siteTitle,
      path: state.Layout.path as LayoutPath[] | undefined,
      notificationCount: state.Notification.notificationCount,
      editableData: state.Layout.siteEditableData,
      objectEditable: state.Layout.siteEditableData !== undefined,
      objectName: state.Layout.siteEditableData?.type,
      objectId: state.Layout.siteEditableData?.id,
      objectRemoved: state.Layout.siteEditableData?.removed,
      menu: state.Layout.siteMenuItems as MenuItemEntry[] | undefined,
    };
  }, areObjectsEqual);
  const [breadcrumbItems, setBreadcrumbItems] = useState<MenuItemEntry[]>([]);

  useEffect(() => {
    const urlSplit = location.pathname.split('/');
    if (urlSplit.length === 1) {
      setBreadcrumbItems([]);
    } else {
      let newBreadcrumb: MenuItemEntry[] = [];
      let currentItem: MenuItemEntry[] | undefined = menu;

      for (let i = 1; i <= urlSplit.length; i++) {
        let innerUrl = '';
        for (let j = 1; j <= i; j++)
          innerUrl = `${innerUrl}/${urlSplit[j]}`;

        currentItem?.forEach((menuEntry) => {
          if (menuEntry.url && menuEntry.url === innerUrl) {
            currentItem = menuEntry.children || [];
            if (!menuEntry.hiddenFromBreadcrumb)
              newBreadcrumb.push(menuEntry);
          }
        });
      }
      if (newBreadcrumb.length + 1 !== urlSplit.length) {
        newBreadcrumb.push({
          key: 'currentPage',
          label: 'title',
        });
      }

      setBreadcrumbItems(newBreadcrumb);
    }
  }, [location, menu]);

  return (
    <Row>
      <Col>
        <div className="page-title-box">
          <div className="page-title-right">
            <Breadcrumb className="m-0">
              <Breadcrumb.Item href="/">Főoldal</Breadcrumb.Item>

              {(breadcrumbItems || []).map((item, index) => {
                return (
                  <Breadcrumb.Item active={(index + 1 === breadcrumbItems.length || item.breadcrumbReadOnly)} key={index} href={item.url}>
                    {(index + 1 === breadcrumbItems.length) ? title : item.label}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>
          
          { (!objectEditable) ? <h4 className={(classOverride) ? classOverride : 'page-title'}>{title}</h4> : <EditableTitle removed={objectRemoved} header={4} classOverride={(classOverride) ? classOverride : 'page-title'} defaultTitle={title} objectType={objectName} id={objectId} onEditCallback={(newTitle: string) => dispatch(changeSiteTitle({
            notificationCount,
            title: newTitle,
            defaultTitle: defaultSiteTitle,
            editable: editableData,
            path
          }))} /> }

          {path &&
            <Breadcrumb>
              {path.map((item, index) => {
                return (
                  <Breadcrumb.Item key={index} href={item.url}>
                    {index === 0 && 'Hely: '}{item.label}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          }
        </div>
      </Col>
    </Row>
  );
}
