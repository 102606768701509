import { MenuItemEntry } from 'types/MenuItemEntry';
import { EditableTitle } from './actions';

enum LayoutActionTypes {
  SET_MENU_ITEMS = '@@layout/SET_MENU_ITEMS',
  CHANGE_SITE_TITLE = '@@layout/CHANGE_SITE_TITLE',
  CHANGE_BREADCRUMB_ITEMS = '@@layout/CHANGE_BREADCRUMB_ITEMS',
  CHANGE_BACKGROUND_DATA = '@@layout/CHANGE_BACKGROUND_DATA'
}

export interface LayoutBackgroundData {
  backgroundColor?: string;
  backgroundImage?: string;
}

export interface LayoutPath {
  url: string;
  label: string;
}

export interface LayoutStateTypes {
  siteTitle?: string;
  defaultSiteTitle?: string;
  siteEditableData?: EditableTitle;
  siteMenuItems?: MenuItemEntry[];
  siteBackgroundData?: LayoutBackgroundData;
  path?: LayoutPath[];
}

export { LayoutActionTypes };
