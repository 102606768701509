import { NetteKanbanWorkspaceEntry } from 'types/KanbanTypes';
import { NetteLabelReadSchema, NetteStatusReadSchema } from 'types/schema';

enum SchemaActionTypes {
  SET_SCHEMA_CACHE_DATA = '@@schema/SET_SCHEMA_CACHE_DATA',
  CLEAR_SCHEMA_CACHE_DATA = '@@schema/CLEAR_SCHEMA_CACHE_DATA',
  UNSET_SCHEMA_CACHE_DATA = '@@schema/UNSET_SCHEMA_CACHE_DATA',
  SCHEMA_DATA_CHANGE_NOTIFICATION = '@@schema/SCHEMA_DATA_CHANGE_NOTIFICATION',
  SCHEMA_DATA_CHANGE_CHAT = '@@schema/SCHEMA_DATA_CHANGE_CHAT',
}

export interface SchemaCacheMap {
  'all_tasklabels': NetteLabelReadSchema[]
  'all_taskstatuses': NetteStatusReadSchema[]
  'all_workspaces': NetteKanbanWorkspaceEntry
}

export interface SchemaStateTypes {
  cacheTtls: {};
  cacheData: {};
  cacheLastUpdated: {};
}

export { SchemaActionTypes };
