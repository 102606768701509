// constants
import { SchemaActionTypes } from './constants';

export interface SchemaActionType<TPayload> {
  type:
    | SchemaActionTypes.SET_SCHEMA_CACHE_DATA
    | SchemaActionTypes.UNSET_SCHEMA_CACHE_DATA
    | SchemaActionTypes.CLEAR_SCHEMA_CACHE_DATA
    | SchemaActionTypes.SCHEMA_DATA_CHANGE_NOTIFICATION
    | SchemaActionTypes.SCHEMA_DATA_CHANGE_CHAT
  payload?: TPayload;
}

export interface SetSchemaCacheDataPayload {
  key: string;
  data: any;
  ttl?: number;
}

export interface SchemaDataChangeNotificationPayload {
  namespace: string;
  object: string;
}

export interface SchemaDataChangeChatPayload {
  namespace: string;
  object: string;
}

export const setSchemaCacheData = (payload: SetSchemaCacheDataPayload): SchemaActionType<SetSchemaCacheDataPayload> => ({
  type: SchemaActionTypes.SET_SCHEMA_CACHE_DATA,
  payload,
});

export const unsetSchemaCacheData = (payload: string): SchemaActionType<string> => ({
  type: SchemaActionTypes.UNSET_SCHEMA_CACHE_DATA,
  payload,
});

export const clearSchemaCacheData = (): SchemaActionType<unknown> => ({
  type: SchemaActionTypes.CLEAR_SCHEMA_CACHE_DATA,
  payload: {}
});

export const schemaDataChangeNotification = (namespace: string, object: string): SchemaActionType<SchemaDataChangeNotificationPayload> => ({
  type: SchemaActionTypes.SCHEMA_DATA_CHANGE_NOTIFICATION,
  payload: {
    namespace,
    object
  },
});

export const schemaDataChangeChat = (namespace: string, object: string): SchemaActionType<SchemaDataChangeChatPayload> => ({
  type: SchemaActionTypes.SCHEMA_DATA_CHANGE_CHAT,
  payload: {
    namespace,
    object
  },
});