// action constants
import { ErrorActionTypes, ErrorStateTypes } from './constants';

// actions
import { ErrorActionType } from './actions';

const INIT_STATE = {
  showError: false,
  errorText: '',
};

const Error = (state: ErrorStateTypes = INIT_STATE, action: ErrorActionType<string | boolean>) => {
  switch (action.type) {
    case ErrorActionTypes.DISPLAY_ERROR:
      return {
        ...state,
        showError: true,
        errorText: action.payload,
      };
    case ErrorActionTypes.ACK_ERROR:
      return {
        ...state,
        showError: false,
        errorText: '',
      };
    default:
      return state;
  }
};

export default Error;
