// constants
import { MenuItemEntry } from 'types/MenuItemEntry';
import { LayoutActionTypes, LayoutBackgroundData, LayoutPath } from './constants';

export interface LayoutActionType<TPayload> {
  type:
    | LayoutActionTypes.SET_MENU_ITEMS
    | LayoutActionTypes.CHANGE_SITE_TITLE
    | LayoutActionTypes.CHANGE_BREADCRUMB_ITEMS
    | LayoutActionTypes.CHANGE_BACKGROUND_DATA;
  payload?: TPayload;
}

export interface ChangeSiteTitlePayload {
  notificationCount: number | undefined;
  title: string | undefined;
  defaultTitle: string | undefined;
  editable: EditableTitle | undefined;
  path?: LayoutPath[];
}

export interface EditableTitle {
  type: string;
  id: number;
  className: string;
  removed?: boolean;
}

export const setMenuItems = (menuItems: MenuItemEntry[]): LayoutActionType<MenuItemEntry[]> => ({
  type: LayoutActionTypes.SET_MENU_ITEMS,
  payload: menuItems
});

export const changeSiteTitle = (payload: ChangeSiteTitlePayload): LayoutActionType<ChangeSiteTitlePayload> => ({
  type: LayoutActionTypes.CHANGE_SITE_TITLE,
  payload,
});

export const changeBreadcrumbItems = (items: any[]): LayoutActionType<any[]> => ({
  type: LayoutActionTypes.CHANGE_BREADCRUMB_ITEMS,
  payload: items
});

export const changeBackgroundData = (data: LayoutBackgroundData): LayoutActionType<LayoutBackgroundData> => ({
  type: LayoutActionTypes.CHANGE_BACKGROUND_DATA,
  payload: data
});