import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import ApiBootstrap from 'components/Api/ApiBootstrap';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { APICore } from 'helpers/api/apiCore';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'react-nestable/dist/styles/index.css';
import { DefaultCustomforms } from 'hooks/useAllDefaultCustomforms';

declare global {
    interface Window {
        apiCoreSingleton: APICore;
        defaultCustomforms: DefaultCustomforms;
        alias: {
          status: any
          label: any
        };
        meta: {
          task: {
            status: {
              ['preview.showPrefix']: boolean;
            }
          }
        } & { [x: string]: any };
    }
}

const apiCoreSingleton = new APICore();
window.apiCoreSingleton = apiCoreSingleton;
window.alias = {
  status: {},
  label: {}
};
window.meta = {
  task: {
    status: {
      'preview.showPrefix': true
    }
  }
}
export const APICoreContext = React.createContext(apiCoreSingleton);
const queryClient = new QueryClient();

ReactDOM.render(
  <APICoreContext.Provider value={apiCoreSingleton}>
    <QueryClientProvider client={queryClient}>
      <Provider store={configureStore()}>
        <ApiBootstrap />
      </Provider>
    </QueryClientProvider>
  </APICoreContext.Provider>,
  document.getElementById('root')
);

reportWebVitals();
