import { all, fork, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
  markChatRead,
  markAllChatsRead,
} from 'helpers/';

// constants
import { ChatActionTypes } from './constants';

interface ReadChatData {
  payload: {
    chatId: number
  };
  type: string;
}

function* readChat({ payload: { chatId }, type }: ReadChatData): SagaIterator {
  try {
    yield call(markChatRead, { chatId });
  } catch (error: any) {
    
  }
}

function* clearChats(): SagaIterator {
  try {
    yield call(markAllChatsRead);
  } catch (error: any) {

  }
}

export function* watchChatRead() {
  yield takeEvery(ChatActionTypes.READ_CHATS, readChat);
}

export function* watchChatsClear() {
  yield takeEvery(ChatActionTypes.CLEAR_CHATS, clearChats);
}

function* chatSaga() {
  yield all([fork(watchChatRead), fork(watchChatsClear)]);
}

export default chatSaga;
