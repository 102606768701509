// account
function login(params: { username: string; password: string }) {
    const baseUrl = '/api/account/adminlogin';

    const formData = new URLSearchParams();
    formData.append('username', params.username);
    formData.append('password', params.password);

    return window.apiCoreSingleton.createForm(`${baseUrl}`, formData);
}

function whoami() {
    const baseUrl = '/api/admin/account/whoami';
    return window.apiCoreSingleton.get(`${baseUrl}`);
}

function rights() {
    const baseUrl = '/api/admin/system/rights/read';
    return window.apiCoreSingleton.get(`${baseUrl}`);
}

function logout() {
    const baseUrl = '/api/admin/account/logout';
    return window.apiCoreSingleton.create(`${baseUrl}`, undefined);
}

export { login, logout, whoami, rights };
