// constants
import { PersistedSettingsActionTypes, PersistedSettingsTypes } from './constants';

export interface PersistedSettingsActionType<TPayload> {
  type:
    | PersistedSettingsActionTypes.SET_SETTINGS_VALUE;
  payload?: TPayload;
}

export interface SimpleKV {
  key: keyof PersistedSettingsTypes;
  value: any;
}

export const setSettingsValue = (payload: SimpleKV): PersistedSettingsActionType<SimpleKV> => ({
  type: PersistedSettingsActionTypes.SET_SETTINGS_VALUE,
  payload,
});