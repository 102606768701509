import { useMemo } from 'react';
import { useQuery } from 'react-query';

import getAllTaskformfields from '../queries/getAllTaskformfields';

import { NetteTaskformfieldReadSchema } from 'types/schema/NetteTaskformfieldSchema';
import useAllDefaultCustomforms from 'hooks/useAllDefaultCustomforms';

interface TaskformfieldsHookReturn {
    loading: boolean;
    data?: NetteTaskformfieldReadSchema[];
    error: any;
    refetch: () => void;
}

export const TaskformfiledSchemaArray = ['company', 'employee', 'meeting', 'phonebook', 'sales', 'support', 'task'] as const;
export type TaskformfiledSchemas = typeof TaskformfiledSchemaArray[number];

export default function useTaskformfields(schema?: TaskformfiledSchemas | TaskformfiledSchemas[]): TaskformfieldsHookReturn {
  const [defaultCustomforms] = useAllDefaultCustomforms();

  const formIds = useMemo(() => {
    if (!schema)
      return undefined;
    
    if (!Array.isArray(schema))
      return defaultCustomforms[schema];
    
    return schema.flatMap(s => defaultCustomforms[s]);
  }, [schema, defaultCustomforms]);

  const taskformfieldsQuery = useQuery({
    queryKey: [`features_taskformfield_getAllTaskformfields${schema ? `ForSchema:${schema}` : ''}`, formIds],
    queryFn: ({ queryKey }) => getAllTaskformfields(queryKey[1] as number[] | undefined),
  });
  const { data, error, refetch } = taskformfieldsQuery;

  return {
    loading: taskformfieldsQuery.isLoading,
    data,
    error,
    refetch,
  }
}