// action constants
import { NotificationActionTypes, NotificationStateTypes } from './constants';

import { NotificationEntry } from 'types/NotificationEntry';

// actions
import { NotificationActionType } from './actions';

const INIT_STATE = {
  notificationCount: 0,
  notifications: [],
};

const Notification = (state: NotificationStateTypes = INIT_STATE, action: NotificationActionType<any>) => {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATIONS:
      const unreadNotificationCount = (action.payload || []).filter((notif: NotificationEntry) => notif.archived === false).length;
      return {
        ...state,
        notificationCount: unreadNotificationCount,
        notifications: (action.payload) ? (action.payload as NotificationEntry[]).sort((a,b)=>b.actionTime-a.actionTime) : [],
      };
    case NotificationActionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationCount: 0,
        notifications: state.notifications.map((notif) => {
          return {
            ...notif,
            archived: true,
          };
        })
      };
    case NotificationActionTypes.READ_NOTIFICATION:
      const newNotifications = (state.notifications || []).map((notif) => {
        if (notif.id !== action.payload.notificationId) return notif;
        return {
          ...notif,
          archived: true,
        };
      });
      const newNotificationCount = newNotifications.filter((notif: NotificationEntry) => notif.archived === false).length;

      return {
        ...state,
        notificationCount: newNotificationCount,
        notifications: newNotifications,
      };
    default:
      return state;
  }
};

export default Notification;
