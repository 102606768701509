import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeBackgroundData } from 'redux/actions';

import { useLocation } from 'react-router';

import useSettingsEntry from 'features/settings/hooks/useSettingsEntry';

export default function RouteChangeListener(): JSX.Element {
    const location = useLocation();
    const dispatch = useDispatch();
    const { data: siteTitle } = useSettingsEntry('work.site_title');

    useEffect(() => {
        document.title = !siteTitle ? 'Avorasoft CRM' : siteTitle;
        dispatch(changeBackgroundData({}));
    }, [location, dispatch, siteTitle]);

    return <></>;
}