import { ChatEntry } from 'types/ChatEntry';

export enum ChatActionTypes {
  SET_CHATS = '@@chat/SET_CHATS',
  CLEAR_CHATS = '@@chat/CLEAR_CHATS',
  READ_CHATS = '@@chat/READ_CHATS'
}

export interface ChatStateTypes {
  chatCount: number
  chats: ChatEntry[]
}
