import { all, fork, takeEvery, call, put } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
  readSchemaEntries,
} from 'helpers/';

// constants
import { SchemaActionTypes } from './constants';
import { AxiosResponse } from 'axios';
import { setSchemaCacheData } from './actions';
import { getCacheKey } from 'utils/cache';

interface SchemaDataChangeNotificationData {
  payload: {
    object: string
    namespace: string
  };
  type: string;
}

function* dataChangeNotification({ payload: { namespace, object }, type }: SchemaDataChangeNotificationData): SagaIterator {
  try {
    const response: AxiosResponse<any> = yield call(readSchemaEntries, { namespace, object });
    if (response.status === 200 && Array.isArray(response.data)) {
      yield put(setSchemaCacheData({
        key: getCacheKey(namespace, object),
        data: response.data
      }));
    }
  } catch (error: any) {
    
  }
}

export function* watchDataChangeNotifications() {
  yield takeEvery(SchemaActionTypes.SCHEMA_DATA_CHANGE_NOTIFICATION, dataChangeNotification);
}

interface SchemaDataChangeChatData {
  payload: {
    object: string
    namespace: string
  };
  type: string;
}

function* dataChangeChat({ payload: { namespace, object }, type }: SchemaDataChangeChatData): SagaIterator {
  try {
    const response: AxiosResponse<any> = yield call(readSchemaEntries, { namespace, object });
    if (response.status === 200 && Array.isArray(response.data)) {
      yield put(setSchemaCacheData({
        key: getCacheKey(namespace, object),
        data: response.data
      }));
    }
  } catch (error: any) {
    
  }
}

export function* watchDataChangeChats() {
  yield takeEvery(SchemaActionTypes.SCHEMA_DATA_CHANGE_CHAT, dataChangeChat);
}

function* schemaCacheSaga() {
  yield all([fork(watchDataChangeNotifications), fork(watchDataChangeChats)]);
}

export default schemaCacheSaga;
